<template>
  <div>



    <!-- Items Slider-->
    <ws-slider
        :items="sliderItems"
        class="mt-5"
        :height="300 "
        width="250"
        hide-header
        not-centered
        all-active
    >
      <template #title>
        <h2 class="wsDARKER"
            :style="`font-size: ${!SM ? 24 : 19}px`"
        >
          {{  $t('wsu_structure.specialty.alumnis.title')  }}
        </h2>
      </template>
      <template #item="{item}">

        <v-sheet
            class="fill-height d-flex flex-column pa-5 wsRoundedSemi mr-5"
            :color="wsLIGHTCARD"
            style="background-size: cover; position: relative; margin-top: 38px"

        >

          <img :src="item.item.img"
               height="76"
               width="76"
               style="border-radius: 50%; object-fit: cover; object-position: top; position: absolute; margin-top: -58px"
               alt=""
               class="mb-4 "

          >
          <h4 class="wsDARKER mt-7" style="max-width: 80%">
            {{ item.item.name}}
          </h4>
          <h5 class="wsDARKLIGHT font-weight-regular pb-3" style="max-width: 80%">
            {{ item.item.position}}
          </h5>
          <h5 class="wsDARKER font-weight-regular" style="max-width: 80%">
            {{ item.item.short_description}}
          </h5>
          <div class="flex-grow-1" />
          <ws-button @click="openDescription(item.item)" class="mt-3" label="Read"></ws-button>
        </v-sheet>
      </template>
    </ws-slider>
    <!-- Details Dialog-->
    <ws-dialog
        v-model="displayDialog"
        fullscreen
        :fullscreen-width="!SM ? 400 : null"
        :title="$t('wsu_structure.specialty.alumnis.testimonial_title')"
        not-centered
        fullscreen-align="right"
        hide-buttons
    >
      <v-img
          v-if="selectedItem.img"
          height="250" class="wsRoundedSemi mr-5"
          position="top"
          :src="selectedItem.img">

      </v-img>
      <h3 class="mt-3">
        {{ selectedItem.name }}
      </h3>
      <h5 class="mt-3 font-weight-regular">
        {{ selectedItem.position }}
      </h5>

      <v-divider class="my-5" style="border-color: var(--wsBACKGROUND)" />

      <ws-text-viewer
          :value="selectedItem.description"
          isPublic
          class="mt-3"
          :video-height="!SM ? 400 : 250"
      />

    </ws-dialog>
  </div>

</template>

<script>
export default {
  name: "alumnisSlider",
  props : {
    items : {
      type : Array,
      default() { return [] }
    }
  },
  data() {
    return {
      displayDialog : false,
      selectedItem : {},
    }
  },
  computed : {
    sliderItems() {
      return [
        {
          value : 'alumnis',
          items : this.items ,
        }
      ]
    }
  },
  methods : {
    openDescription(item) {
      this.selectedItem = this.COPY(item)
      this.displayDialog = true
    }
  }
}
</script>



<style scoped>

</style>