<template>
  <div style="width: 100%; max-width: 740px; position: relative">
    <h2 class="wsDARKER mb-2"
        :class="[{'mt-12' : !SM} , {'mt-6' : SM}]"
        :style="`font-size: ${!SM ? 24 : 19}px`"
    >
      {{  $t('wsu_structure.specialty.guarantor.title')  }}
    </h2>


    <v-row >
      <v-col v-if="guarantor.img" cols="12" md="5">
        <div style="position: relative;">
          <img
              v-if="guarantor.img"
              :src="guarantor.img"
              width="100%"
              class="wsRoundedSemi"
              position="top"
          >
        </div>

      </v-col>
      <v-col cols="12" :md="!guarantor.img ? 12 : 7">
        <div class="fill-height d-flex flex-column justify-center">

          <h3 class="wsDARKER">
            {{ guarantor.name }}
          </h3>
          <h5 class="font-weight-regular mt-2 wsDARKER" style="max-width: 400px">
            {{ guarantor.short_description }}
          </h5>
          <div>
            <ws-button @click="displayDialog = true" label="MoreDetails" class="mt-5"></ws-button>
          </div>

        </div>
      </v-col>
    </v-row>



    <ws-dialog
        v-model="displayDialog"
        fullscreen
        :fullscreen-width="!SM ? 400 : null"
        :title="$t('wsu_structure.specialty.guarantor.title')"
        not-centered
        fullscreen-align="right"
        hide-buttons
    >
      <v-img
          v-if="guarantor.img"
          height="250" class="wsRoundedSemi mr-5"
          position="top"
          :src="guarantor.img">

      </v-img>
      <h3 class="mt-3">
        {{ guarantor.name }}
      </h3>
      <h5 class="mt-3 font-weight-regular">
        {{ guarantor.short_description }}
      </h5>

      <v-divider class="my-5" style="border-color: var(--wsBACKGROUND)" />

      <ws-text-viewer
          :value="guarantor.description"
          isPublic
          class="mt-3"
          :video-height="!SM ? 400 : 250"
      />

    </ws-dialog>

</div>
</template>

<script>
export default {
  name: "programGuarantor",
  props : {
    guarantor : {
      type : Object,
      default() { return {}}
    }
  },
  data() {
    return {
      displayDialog : false
    }
  },
  computed : {
  }
}
</script>

<style scoped>

</style>