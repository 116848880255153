<template>
  <div style="width: 100%; max-width: 740px;">
    <h2 class="wsDARKER mb-2"
        :class="[{'mt-12' : !SM} , {'mt-6' : SM}]"
        :style="`font-size: ${!SM ? 24 : 19}px`"
    >
      {{  $t('Вартість навчання на контрактній формі')  }}
    </h2>

    <ws-data-table
        :headers="headers"
        :items="items"
        style="border : 1px solid var(--wsBACKGROUND);"
        class="wsRoundedLight"
        no-footer
        disable-pagination
    >
    </ws-data-table>

</div>
</template>

<script>
export default {
  name: "programPrice",
  data() {
    return {
      items : [
        { degree : 'Бакалавріат' ,
          price_per_year : '12 000 грн',
          price_total : '74 000 грн',
        },
        { degree : 'Магістратура' ,
          price_per_year : '24 000 грн',
          price_total : '54 000 грн',
        },
        { degree : 'Бакалавріат - заочна форма' ,
          price_per_year : '12 000 грн',
          price_total : '74 000 грн',
        },
        { degree : 'Магістратура - заочна форма' ,
          price_per_year : '24 000 грн',
          price_total : '54 000 грн',
        }
      ]
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Degree') , value : 'degree' },
        { text : this.$t('PerYear') , value : 'price_per_year' },
        { text : this.$t('AllPeriod') , value : 'price_total' }
      ]
    }
  }
}
</script>

<style scoped>

</style>