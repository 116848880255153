<template>
  <div>
    <h2 class="wsDARKER mb-2"
        :class="[{'mt-12' : !SM} , {'mt-6' : SM}]"
        :style="`font-size: ${!SM ? 24 : 19}px`"
    >
      {{  $t('wsu_structure.specialty.enroll_committee.title')  }}
    </h2>

    <div v-for="(item,index) in entity.content" :key="index"
         style="border : 1px solid var(--wsBACKGROUND)"
         class="wsRoundedSemi pa-6 mb-5"
    >

      <div class="d-flex align-center  ">
        <img
            v-if="item.img"
            :src="item.img"
            width="120px"
            height="120px"
            style="border-radius: 50%; object-fit: cover"
            position="top"
            class="mr-5"
        >
        <div>

          <h3 >{{ item.name }}</h3>
          <h3 class="font-weight-regular"> {{item.position}} </h3>

        </div>
      </div>

      <v-divider
          class="my-5" style="border-color: var(--wsBACKGROUND)"
      />

      <h4 v-for="(phone,i) in item.phone" :key="i+ 'phone'"
          class="wsACCENT" >
        {{ phone }}
      </h4>

      <div v-if="item.email && item.email.length" class="mt-5">
        <a v-for="(phone,i) in item.email" :key="i+ 'phone'"

           class="noUnderline linkHover wsACCENT"
           :href="`mailto:${email}`">
          <h4 class="wsACCENT" v-for="(email,i) in item.email" :key="i+ 'phone'">
            {{ email }}
          </h4>
        </a>
      </div>




    </div>


  </div>

</template>

<script>
export default {
  name: "programEnrollmentCommittee",
  props : {
    entity : {
      type : Object,
      default() { return {}}
    }
  }
}
</script>



<style scoped>

</style>