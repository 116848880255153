<template>
  <div style="background-color: var(--wsWHITE)" class="pb-16">
    <contact-form-dialog
        title="Запис на консультацію"
        v-if="displayContactDialog"
        v-model="displayContactDialog"
        :origin="entity.name"
    />

    <!-- Intro -->
    <v-sheet :color="wsACCENT">
      <portal-target v-if="SM" name="market-course-image">
      </portal-target>
      <div style="width: 100%; max-width: 1172px" class="mx-auto pt-4  px-4">
        <!-- Breadcrumbs -->
        <!--      <h5 class="wsWHITE">Breadcrumbs</h5>-->

        <!-- Course info -->
        <div class="d-flex align-end justify-space-between" :class="[{'mt-6' : !SM}]">
          <div class="mr-6 pb-6" >
            <h1 class=" wsWHITE" :style="`font-size: ${!SM ? 42 : 24}px`">{{ entity.name }}</h1>
            <h4 class="mt-5 wsWHITE font-weight-regular">{{ entity.short_description }}</h4>
          </div>
          <portal  to="market-course-image" :disabled="!SM">
            <v-img
                :class="[{wsRoundedHalfTop: !SM}]"
                :src="entity.logo"
                :width="!SM ? 375 : null"
                :max-width="!SM ? 375 : null"
                height="288"
            />
          </portal>

        </div>
      </div>

      <portal-target v-if="SM" name="market-course-params"></portal-target>

    </v-sheet>

    <!-- Main Content -->
    <div style="width: 100%; max-width: 1172px; position: relative " class="mx-auto px-4 d-flex flex-row justify-space-between">

      <!-- Main Content-->
      <div class="mr-6 flex-grow-1" style="position: relative" >

        <!-- Course Full Description -->
        <div v-if="entity.description && entity.description.length" style="width: 100%; max-width: 740px;">
          <h2 class="wsDARKER mb-2"
              :class="[{'mt-12' : !SM} , {'mt-6' : SM}]"
              :style="`font-size: ${!SM ? 24 : 19}px`"
          >
            {{  $t('courseDescription')  }}
          </h2>
          <ws-text-viewer
              :value="entity.description"
              isPublic
              class="mt-3"
              :video-height="!SM ? 400 : 250"
          />

        </div>

        <!-- Pricing -->
        <program-price v-if="false" />

        <!-- How to become student -->
        <ws-accordion-variant
            v-if="entity.enroll_faq && entity.enroll_faq.content && entity.enroll_faq.content.length"
            :title="$t('wsu_structure.specialty.how_to_apply.title')"
            :items="entity.enroll_faq.content"
        >
          <template #expanded.content="{item}">
            <ws-text-viewer
                :value="item.content"
                isPublic
                class="mt-3"
                :video-height="!SM ? 400 : 250"
            />
          </template>
        </ws-accordion-variant>

        <!-- Features -->
        <div v-if="entity.features && entity.features.length">

          <h2 class="wsDARKER mb-2"
              :class="[{'mt-12' : !SM} , {'mt-6' : SM}]"
              :style="`font-size: ${!SM ? 24 : 19}px`"
          >
            {{  $t('wsu_structure.specialty.features.title')  }}
          </h2>

          <v-sheet :color="wsLIGHTCARD" class="wsRoundedHalf mt-2 pa-5 py-8">
            <v-row>
              <v-col
                  v-for="(item,i) in entity.features" :key="i"
                  cols="12" md="6"
                  :class="[{'mb-4' : i < entity.features.length - 2 && !SM}]"
              >
                <div class="d-flex ">
                  <div class="d-flex">
                    <v-icon :color="wsACCENT" class="mr-2 mb-auto ">mdi-check</v-icon>
                    <h5 class="font-weight-regular wsACCENT ">
                      {{ item.text }}
                    </h5>
                  </div>

                </div>

              </v-col>
            </v-row>

          </v-sheet>

        </div>

        <!-- Guarantor -->
        <program-guarantor
            v-if="entity.guarantor"
            :guarantor="entity.guarantor"
        />

        <!-- Career -->
        <program-career
            v-if="entity.career"
            :entity="entity.career"
        />

        <!-- Study Program -->
        <program-study-program
            v-if="entity.program "
            :entity="entity.program"
        />

        <!-- Questions -->
        <ws-accordion-variant
            v-if="entity.faq && entity.faq && entity.faq.content && entity.faq.content.length"
            :title="$t('FAQ')"
            :items="entity.faq.content"
            numbered
        >
          <template #expanded.content="{item}">
            <ws-text-viewer
                :value="item.content"
                isPublic
                class="mt-3"
                :video-height="!SM ? 400 : 250"
            />
          </template>
        </ws-accordion-variant>

        <!-- Alumni -->
        <alumnis-slider
            v-if="entity.alumni && entity.alumni.content &&  entity.alumni.content.length > 0"
            :items="entity.alumni.content"
        />

        <!-- Enrollment Committee -->
        <program-enrollment-committee
            v-if="entity.enroll_committee && entity.enroll_committee.content && entity.enroll_committee.content.length"
            :entity="entity.enroll_committee"
        />


      </div>

      <!-- Program Params-->
      <portal to="market-course-params" :disabled="!SM">
        <div>
          <v-sheet
              :class="[{wsRoundedHalfBottom : !SM} , {'pa-6' : !SM} , {'pa-4' : SM}]"

              :color="!SM ? wsLIGHTCARD : wsACCENT"
              :width="!SM ? '375px' : null"
              :min-width="!SM ? '375px' : null"
              :dark="SM"
          >
            <h1 :class="!SM ? wsDARKER : wsWHITE" :style="`font-size: ${!SM ? 34 : 24}px`"> {{ discountPice }}</h1>
            <h4 v-if="entity.discount" class="font-weight-regular" :class="!SM ? wsDARKER : wsWHITE">
              <span style="text-decoration: line-through">{{ coursePrice }}</span>
            </h4>

            <ws-button @click="displayContactDialog = true" label="Подати заявку" block></ws-button>


            <div v-for="(item, index) in programParams" :key="index"
                class="d-flex align-center mt-6">

              <v-icon :color="!SM ? wsACCENT : wsLIGHTCARD" class="mr-4">mdi-school-outline</v-icon>
              <div>
                <h5 :class="!SM ? wsACCENT : wsLIGHTCARD">
                  {{ item.title }}:
                </h5>
                <h5 :class="!SM ? wsACCENT : wsLIGHTCARD" class="font-weight-regular" v-html="item.value">
                </h5>
              </div>
            </div>


            <!-- Qualification-->
<!--            <div  class="d-flex align-center mt-6">-->
<!--              <v-icon :color="!SM ? wsACCENT : wsLIGHTCARD" class="mr-4">mdi-school-outline</v-icon>-->
<!--              <div>-->
<!--                <h5 :class="!SM ? wsACCENT : wsLIGHTCARD">-->
<!--                  {{ $t('Qualification') }}:-->
<!--                </h5>-->
<!--                <h5 :class="!SM ? wsACCENT : wsLIGHTCARD" class="font-weight-regular">-->
<!--                  Бакалавр освіти (англійська мова і література), бакалавр психології-->
<!--                </h5>-->
<!--              </div>-->
<!--            </div>-->

            <!-- Specialty Code-->
            <div  class="d-flex align-center mt-6">
              <v-icon :color="!SM ? wsACCENT : wsLIGHTCARD" class="mr-4">mdi-code-brackets</v-icon>
              <div>
                <h5 :class="!SM ? wsACCENT : wsLIGHTCARD">
                  {{ $t('SpecialtyCode') }}:
                </h5>
                <h5 :class="!SM ? wsACCENT : wsLIGHTCARD" class="font-weight-regular">
                  {{  entity.code  }}
                </h5>
              </div>
            </div>


            <!-- Degrees-->
            <div  class="d-flex align-center mt-6">
              <v-icon :color="!SM ? wsACCENT : wsLIGHTCARD" class="mr-4">mdi-certificate-outline</v-icon>
              <div>
                <h5 :class="!SM ? wsACCENT : wsLIGHTCARD">
                  {{ $t('SpecialtyDegree') }}:
                </h5>
                <h5 v-for="(degree,index) in entity.degrees" :key="index"
                    :class="!SM ? wsACCENT : wsLIGHTCARD" class="font-weight-regular">
                  {{ degree.level }}

                </h5>
              </div>
            </div>

            <!-- Study Field-->
            <div  class="d-flex align-center mt-6">
              <v-icon :color="!SM ? wsACCENT : wsLIGHTCARD" class="mr-4">mdi-fountain-pen-tip</v-icon>
              <div>
                <h5 :class="!SM ? wsACCENT : wsLIGHTCARD">
                  {{ $t('StudyField') }}:
                </h5>
                <h5 :class="!SM ? wsACCENT : wsLIGHTCARD" class="font-weight-regular">
                  {{ entity.study_field }}  {{ entity.study_field_name }}
                </h5>
              </div>
            </div>

            <!-- Department-->
            <div  class="d-flex align-center mt-6">
              <v-icon :color="!SM ? wsACCENT : wsLIGHTCARD" class="mr-4">mdi-bank-outline</v-icon>
              <div>
                <h5 :class="!SM ? wsACCENT : wsLIGHTCARD">
                  {{ $t('Department') }}:
                </h5>
                <h5 :class="!SM ? wsACCENT : wsLIGHTCARD" class="font-weight-regular">
                  {{ entity.department_name }}
                </h5>
              </div>
            </div>


          </v-sheet>
        </div>
      </portal>

    </div>

  </div>
</template>

<script>
import {mapActions} from "vuex";
import alumnisSlider from "@modules/wsu_structure/components/public/defaultDesign/alumnisSlider.vue";
import programPrice from "@modules/wsu_structure/components/public/defaultDesign/programPrice.vue";
import programGuarantor from "@modules/wsu_structure/components/public/defaultDesign/programGuarantor.vue";
import programEnrollmentCommittee
  from "@modules/wsu_structure/components/public/defaultDesign/programEnrollmentCommittee.vue";
import programCareer from "@modules/wsu_structure/components/public/defaultDesign/programCareer.vue";
import programStudyProgram from "@modules/wsu_structure/components/public/defaultDesign/programStudyProgram.vue";
import contactFormDialog from "@/components/AvalonEditor/defaultSite/UI/contactFormDialog.vue";

export default {
  name: "PublicEducationProgram",
  components: {
    contactFormDialog,
    alumnisSlider,
    programPrice,
    programGuarantor,
    programEnrollmentCommittee,
    programCareer,
    programStudyProgram
  },
  data() {
    return {
      displayContactDialog : false,
      entity : {
        degrees : [],
        guarantor : {}
      },

      applySteps : [
        { title : 'Крок 1. Підготовчий. Заявка про намір',
          text : 'Подати заявку на вступ через сайт університету та ввести свої контактні дані. З Вами звʼяжуться представники відбіркової комісії та нададуть детальну консультацію щодо вступу та проконсультують по всім питанням, які Вас цікавлять. Отримайте вичерпні консультації щодо особливостей вступної кампанії поточного року та порядку вступу на обрану освітню програму та дізнайтеся про спеціальні умови вступу чи участі в конкурсному відборі.',
          expanded : true
        },
        { title : 'Крок 2. Основний. Вступ',
          text : 'Скласти національний мультипредметний тест (НМТ), отримати сертифікат; написати мотиваційний лист про бажання навчатися за обраною освітньою програмою; • NB!!! Зразки мотиваційних листів подати заяву на обрану освітню програму разом з мотиваційним листом через особистий електронний кабінет вступника для участі в конкурсі на місце державного замовлення, присвоїти заяві пріоритет №1 (бюджет) або взяти участь у конкурсі на навчання за кошти фізичних та юридичних осіб (контракт).',
          expanded : false
        },
        { title : 'Крок 3. Зарахування',
          text : 'Відстежувати статус своєї заяви в особистому кабінеті; отримати рекомендації до зарахування та повідомлення про отримання права здобувати вищу освіту за державним замовленням; виконати вимоги до зарахування, підтвердити вибір навчання в Горлівському інституті іноземних мов в електронному кабінеті і накласти кваліфікований електронний підпис (КЕП) або надіслати скан-копію відповідної заяви на електронну адресу відбіркової комісії. Після зарахування на освітню програму, очікувати на інформацію від деканату щодо термінів та заходів нульового семестру – знайомство з інститутом, правами та обов\'язками студента, особливостями навчання за програмою тощо.',
          expanded : false
        },
      ]
    }
  },
  computed : {
    programParams() {
      let degreeText = ''
      this.entity.degrees.forEach((degree) => {
        degreeText += degree.level + "<br>"
      })

      let items = [
        { title : this.$t('Degree') ,
          value : degreeText ,
          icon : 'mdi-school'
        }
      ]

      return items
    },
    business() {
      return this.$store.state.business.selectedBusiness || {}
    },
    educationProgram() {
      if (!this.$route.params) {
        return null
      }
      return this.$route.params.educationProgram
    }
  },
  methods : {
    ...mapActions('wsu_structure' , [
        "GET_SPECIALTY_PUBLIC"
    ]),

    async initPage() {
      let result = await this.GET_SPECIALTY_PUBLIC(this.educationProgram)
      if (!result) {
        return this.ERROR()
      }
      this.entity = result
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>



<style scoped>

</style>